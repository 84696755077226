import React, { useState } from 'react'
import { Button, Form, Modal, Spinner } from 'react-bootstrap'
import { termsNConditions } from '../../../config/constant'
import { api } from '../../../Services/api'
import { TermsNConditionsWrapper } from './style'
import { browserName, browserVersion } from "react-device-detect";
import { useSelector } from 'react-redux'
import { useEffect } from 'react'
import axios from 'axios'
import { useNavigate } from 'react-router-dom'
import UIModal from '../UIModal'

const TermsNConditions = () => {
    const authToken = useSelector((state) => state.Auth.authToken)
    const [loading, setLoading] = useState(false)
    const [ip, setIP] = useState('');
    const navigate = useNavigate()

    // ? creating function to load ip address from the API
    const getIpData = async () => {
        try {
            const res = await axios.get('https://geolocation-db.com/json/')
            console.log(res.data);
            setIP(res.data.IPv4)
        } catch (error) {
            console.log("[getIpData] error-->", error)
        }
    }

    useEffect(() => {
        getIpData()
    }, [])

    const postBrokerAgreement = async () => {
        setLoading(true)
        try {
            const data = {
                browser: (`${browserName}, ${browserVersion}`),
                ip: ip
            }
            const response = await api.post("/bid-leads/broker-agreement", data, {
                headers: {
                    authorizationtoken: authToken
                }
            })
            setLoading(false)
            localStorage.setItem('isFirstTimeLoggin', false);
            navigate("/home/dashBoard")
            console.log("[postBrokerAgreement] response-->", response)
        }
        catch (error) {
            setLoading(false)
            console.log("[postBrokerAgreement] error-->", error.response)
        }
    }

    const handleAcceptTerms = (e) => {
        e.preventDefault()
        postBrokerAgreement()
    }

    return (
        <TermsNConditionsWrapper>
            <UIModal
                show={true}
                size='xl'
                header={<Modal.Title
                    className='text-center'
                    id="example-custom-modal-styling-title">
                    <TermsNConditionsWrapper>
                        <div className='termsNconditions-label'>Terms of Use</div>
                    </TermsNConditionsWrapper>
                </Modal.Title>}
            >
                <TermsNConditionsWrapper>
                    <div className='contents'>
                        <div className='welcome-content'> {termsNConditions.welcome} </div>
                        <TermsNConditionsWrapper>
                            <div className='preface-content'> {termsNConditions.preface} </div>
                        </TermsNConditionsWrapper>
                        <>
                            {termsNConditions.conditions.map((condition, index) => {
                                return (
                                    <div className='mt-2' key={index}> {condition} </div>
                                )
                            })}
                        </>
                        <div className='note'>{termsNConditions.note}</div>
                    </div>
                </TermsNConditionsWrapper>

                <TermsNConditionsWrapper>
                    <div className='agreement-confirm'>
                        <Form className='d-flex justify-content-center align-items-center' onSubmit={handleAcceptTerms}>
                            <Form.Group className="checkbox termscondition_checkbox">
                                <Form.Check
                                    required
                                    feedback="You must agree before submitting."
                                    feedbackType="invalid"
                                />
                            </Form.Group>
                            <Button type="submit" className='confirm-button'>{loading ? <Spinner size="sm" animation="border" /> : "Accept"}</Button>
                        </Form>
                    </div>
                </TermsNConditionsWrapper>

            </UIModal>
        </TermsNConditionsWrapper>
    )
}

export default TermsNConditions