import { ActionTypes } from './actions';

const setDashboardData = response => {
    return {
        type: ActionTypes.SET_DASHBOARD_DATA,
        payload: response
    }

}

export { setDashboardData,};