import moment from 'moment'
import React from 'react'
import { Table } from 'react-bootstrap'
import UICardView from './style'
import jwtDecode from 'jwt-decode'

const TopThreeBids = ({ viewAllData }) => {
    const profileData = jwtDecode(localStorage.getItem('token'))
    const brokerId = profileData?.broker_id
    return (
        <UICardView>
            {viewAllData.length !== 0 &&
                <Table hover borderless size="sm">
                    <thead>
                        <tr className="text-center fw-bold table-font-size">
                            <th>Bid ID</th>
                            <th>Bid Time</th>
                            <th>Bid Amount</th>
                        </tr>
                    </thead>
                    <tbody className='text-center'>

                        {viewAllData && viewAllData.map((oneData, index) => {
                            return index <= 2 && (
                                <tr key={oneData.unique_id}
                                >
                                    <td className='text-center unique-id'>{oneData.unique_id}</td>
                                    <td>{moment(oneData.bid_date_time).format("h:mm:ss a")}</td>
                                    <td className='fw-bold'>{brokerId === oneData.broker_id ? <span className={brokerId === oneData.broker_id ? "your_bid" : ""}>Your Bid - ${oneData.bid_amount}</span> : `$${oneData.bid_amount}`}</td>
                                </tr>
                            )
                        })}

                    </tbody>

                </Table>}
        </UICardView>
    )
}

export default TopThreeBids