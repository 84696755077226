import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { useNetworkState } from 'react-use';
import { Route, BrowserRouter as Router, Routes } from "react-router-dom";
import { ThemeProvider } from 'styled-components';
import { GlobalStyles } from './assets/styles/js/GlobalStyles';
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min.js";
import Layout from "./components/Pages/Layout/main";
import config from './config/config';
import Login from './components/Pages/Login';
import ForgotPassword from "./components/Pages/ForgotPassword";
import PageNotFound from "./components/Pages/PageNotFound";
import BidHistory from "./components/Pages/BidHistory";
import HowItWorks from "../src/components/Pages/HowItWorks";
import Dashboard from "../src/components/Pages/Portal/Dashboard";
import Profile from "./components/Pages/Profile";
import NoInternet from "./components/Reuseable/ErrorPage/NoInternet";
import ProtectedRoute from '../src/Auth/protectedRoute';
import TermsNConditions from "./components/Reuseable/TermsNConditions/TermsNConditions";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query"

const { theme, darktheme } = config;
export const isOnlineContext = React.createContext();
export const client = new QueryClient()

const App = () => {
  const NetworkState = useNetworkState();
  const netState = NetworkState.online;
  const darkmode = useSelector((state) => state.Layout.darkmode);
  const [online, Setonline] = useState(netState);
  const isOnlineContextValue = { online };

  useEffect(() => {
    Setonline(netState)
  }, [netState])

  const UserInfo = [
    {
      user: true,
      tag: "BidLead Login",
      api: "/broker-auth/login"
    },
  ]

  return (
    <ThemeProvider theme={darkmode ? darktheme : theme} >
      <isOnlineContext.Provider value={isOnlineContextValue}>
        <GlobalStyles />
        <QueryClientProvider client={client}>
          <Router basename={process.env.PUBLIC_URL}>
            <Routes>
              <Route path="" element={<NoInternet />}>
                <Route path="/" element={<Login props={UserInfo[0]} />} />
                <Route path="login" element={<Login props={UserInfo[0]} />} />
                <Route path="forgot-password" element={<ForgotPassword />} />
                <Route path="termsOfUse" element={<TermsNConditions />} />

                <Route element={<ProtectedRoute />}>
                  <Route path="home" element={<Layout />}>
                    <Route path="dashBoard" element={<Dashboard />} />
                    <Route path="BidHistory" element={<BidHistory />} />
                    <Route path="Howitworks" element={<HowItWorks />} />
                    <Route path="Profile" element={<Profile />} />
                  </Route>
                </Route>
              </Route>

              <Route path="/noInternet" element={<NoInternet />}></Route>
              <Route path="*" element={<PageNotFound />}></Route>
            </Routes>
          </Router>
        </QueryClientProvider>
      </isOnlineContext.Provider>
    </ThemeProvider>
  );
}
export default App;

