import React, { useState, useEffect, useRef } from 'react';
import { Button, Card, Col, Container, Row, Spinner, Form } from "react-bootstrap";
import { Alert, IconButton, Popover, Snackbar } from '@mui/material';
import moment from 'moment-timezone';
import User from "../../../assets/images/UICardImages/User_Profile.jpg";
import 'bootstrap/dist/css/bootstrap.min.css'
import UICardView from "./style";
import { useForm } from "react-hook-form"
import { yupResolver } from "@hookform/resolvers/yup"
import * as yup from 'yup';
import { api, lambdaUrl } from '../../../Services/api';
import ViewAll from '../UIModal/ViewAll';
import TopThreeBids from './TopThreeBids';
import { useFetch } from '../../../Hooks/useFetch';
import HelpIcon from '@mui/icons-material/Help';
import jwtDecode from 'jwt-decode';
import axios from 'axios';
import { maxBidNote } from '../../../config/constant';
import MaxBidRemainder from '../UIModal/MaxBidRemainder';
import ConfirmModal from '../UIModal/ConfirmModal';

const schema = yup.object().shape({
  bidAmount: yup.number("Enter Number")
    .positive("Enter Positive Number")
    .integer()
    .required("Amount is Required")
    .typeError('Amount is required')
})

const UICard = ({ card, refetch, bidEndTime }) => {

  const [open, setOpen] = useState(false)
  const [severity, setSeverity] = useState('')
  const [alertmsg, setAlertMsg] = useState('');
  const [alertKey, setAlertKey] = useState('');
  const [anchorEl, setAnchorEl] = useState(null);
  const [placeBidLoader, setPlaceBitLoader] = useState(false);
  const [viewAllModal, setViewAllModal] = useState(false)
  const [openConfirmModal, setOpenConfirmModal] = useState(false)
  const profileData = jwtDecode(localStorage.getItem('token'))
  const [maxBidAmountAdded, setMaxBidAmountAdded] = useState(false) // ? To check whether the maxBidAmount is added on Frontend or not
  const [maxBidAmountSet, setMaxBidAmountSet] = useState(false) // ? To check whether the maxBidAmount is setted on Backend or not
  const [isFirstTimeSettingMaxBid, setIsFirstTimeSettingMaxBid] = useState(false)
  const [maxBidAmountRemainderModal, setMaxBidAmountRemainderModal] = useState(false)
  const [removeMaxBidProcessing, setRemoveMaxBidProcessing] = useState("")
  const [lastUpdatedBidAmountTime, setLastUpdatedBidAmounTime] = useState(0)
  const [maxBidLoading, setMaxBidLoading] = useState(false)
  const [focusedMaxBidAmount, setFocusedMaxBidAmount] = useState(false)
  const modalData = useRef('')

  useEffect(() => {
    if (alertmsg.length > 0) {
      setOpen(true)
    } else {
      setOpen(false)
    }
  }, [alertmsg])

  const handleClose = () => {
    setSeverity('')
    setAlertMsg('')
    setOpen(false);
  }

  const { register, unregister, handleSubmit, formState: { errors }, reset, setValue } = useForm({
    defaultValues: {
      bidAmount: ""
    },
    mode: "onChange",
    resolver: yupResolver(schema),
    shouldUnregister: true,
  })

  useEffect(() => {
    if (maxBidAmountRemainderModal) {
      setIsFirstTimeSettingMaxBid(false)
    } else {
      setIsFirstTimeSettingMaxBid(true)
    }
  }, [maxBidAmountRemainderModal])

  const broker_id = profileData?.broker_id
  const email_id = profileData?.email

  const { data: maxBidAmount, refetch: refetchMaxBidAmount, isLoading: maxBidFetchLoading } = useFetch(`maxBidAmount-${card.application_id}`,
    `${lambdaUrl}/1_autobidding/maximum-bid-amount/${broker_id}/${card.application_id}`,
    (data) => {

      if (data?.status) setIsFirstTimeSettingMaxBid(data?.data?.FirstTime)

      if (!data?.status || focusedMaxBidAmount || maxBidAmountRemainderModal) {
        return
      }

      if (data?.data?.MaxBidAmount) {
        setMaxBidAmountAdded(true)
        setMaxBidAmountSet(true)
      } else {
        setMaxBidAmountAdded(false)
        setMaxBidAmountSet(false)
      }

      maxBidAmountSetValue("maxBidAmount", data?.data?.MaxBidAmount || "")

    }, () => { }, 5000, !!broker_id && !!card.application_id)

  const minmumAllowedMaxBid = parseInt(card.current_bid) + 3

  const maxBidAmountSchema = yup.object().shape({
    maxBidAmount: yup.number("Enter Number")
      .positive("Enter Positive Number")
      .min(minmumAllowedMaxBid, "Your maximum bid must be higher than " + (minmumAllowedMaxBid - 1))
      .integer()
      .required("Amount is Required")
      .typeError('Amount is required')
  })

  const { register: maxBidAmountReg, handleSubmit: maxBidHandleSubmit, formState: { errors: maxBidAmountError, }, setValue: maxBidAmountSetValue, getValues, setFocus } = useForm({
    defaultValues: {
      maxBidAmount: maxBidAmount?.data?.MaxBidAmount || ""
    },
    mode: "onChange",
    resolver: yupResolver(maxBidAmountSchema),
    shouldUnregister: true,
  })

  useEffect(() => {
    if (maxBidAmountAdded && getValues("maxBidAmount") === "") {
      setFocus("maxBidAmount")
    }
  }, [maxBidAmountAdded, setFocus, getValues])

  const { data: viewAllData, isLoading: viewAllLoading, refetch: viewAllRefetch } = useFetch("ViewAllData", `/bid-leads/live-auction/details/${card.application_id}`, () => { }, () => { }, false, false)

  const postAmount = async (amount) => {
    setPlaceBitLoader(true);
    setValue("bidAmount", amount)
    try {
      let data = JSON.stringify({
        application_id: card.application_id,
        bid_amount: amount
      })

      const response = await api.post("/bid-leads/auction", data, {
        headers: {
          authorizationtoken: localStorage.getItem("token")
        }
      })
      if (response) {
        setPlaceBitLoader(false);
      }
      setAlertMessage("success", response.data.message)
      unregister("bidAmount")
      reset({ bidAmount: "" })
      refetch() // ? calling the get method for the dashBoardData, after posting the Amount
    }
    catch (error) {
      setPlaceBitLoader(false);
      error.response.status === 401 ? setAlertMessage("info", error.response?.data?.message) : setAlertMessage("error", error.response?.data?.message)
    }
  }

  const handleViewAllButton = () => {
    setViewAllModal(true)
    viewAllRefetch()
  }

  useEffect(() => {
    if (viewAllModal) {
      viewAllRefetch()
    }
  }, [viewAllModal, card, viewAllRefetch])

  const setAlertMessage = (severity, responseMsg) => {
    setAlertKey(crypto.randomUUID())
    setSeverity(severity)
    setAlertMsg(responseMsg)
  }

  const handleMoreThanFifty = (amount) => {
    const data = {
      current_bid: parseInt(card.current_bid),
      your_amount: amount
    }
    modalData.current = data
    setOpenConfirmModal(true)
  }

  const onSubmit = (e) => {
    // ? Making The User to Confirm 
    return (parseInt(e.bidAmount) >= parseInt(card.current_bid) + 50) ? handleMoreThanFifty(e.bidAmount) : postAmount(parseInt(e.bidAmount))
  }

  const normalizeNumber = (value) => {
    return value.replace(/[^0-9]/g, '')
  }

  const openPopOver = Boolean(anchorEl);

  function getCurrentTimestamp() {
    return Date.now()
  }

  const maxBidUpdateInterval = 5000 //? 5 seconds  

  const postMaxBidAmount = async (amount) => {
    setMaxBidLoading(true)
    try {
      const response = await axios.patch(`${lambdaUrl}/1_autobidding/maximum-bid-amount/${broker_id}/${card.application_id}`,
        amount, {
        headers: {
          authorizationtoken: localStorage.getItem("token")
        }
      })
      setMaxBidLoading(false)
      setMaxBidAmountAdded(true)
      setMaxBidAmountSet(true)
      refetchMaxBidAmount()
      setAlertMessage("success", response.data.message)
    } catch (error) {
      setMaxBidLoading(false)
      error.response.status === 401 ? setAlertMessage("info", error.response?.data?.message) : setAlertMessage("error", error.response?.data?.message)
    }
  }

  const maxBidAmountSubmit = async (e) => {
    const timeZone = moment.tz.guess()
    const utcToGuessedTimeZoneFormat = moment.utc(getCurrentTimestamp()).tz(timeZone).format('YYYY-MM-DD hh:mm:ss')
    const currentTime = new Date().getTime()
    if (isFirstTimeSettingMaxBid) {
      setMaxBidAmountRemainderModal(true)
      return
    }
    if ((currentTime - lastUpdatedBidAmountTime < maxBidUpdateInterval) && maxBidAmount?.data?.MaxBidAmount) {
      setRemoveMaxBidProcessing("Processing… It can take up to 20 seconds to Update your bid.")
      const res = await resolveAfter("resolved", maxBidUpdateInterval - (currentTime - lastUpdatedBidAmountTime))
      if (res === "resolved") {
        setRemoveMaxBidProcessing("")
      } else {
        setRemoveMaxBidProcessing("Processing… It can take up to 20 seconds to Update your bid.")
      }
    }
    setLastUpdatedBidAmounTime(currentTime)
    setMaxBidAmountAdded(true)
    setMaxBidAmountSet(true)
    const data = {
      MaxBidAmount: e.maxBidAmount,
      TimeStamp: utcToGuessedTimeZoneFormat,
      emailid: email_id,
      AuctionEndTime: bidEndTime
    }
    postMaxBidAmount(data)
  }

  const handleSetBidConfirm = () => {
    setMaxBidAmountRemainderModal(false)
    maxBidAmountSubmit({ maxBidAmount: getValues("maxBidAmount") })
  }

  const resolveAfter = (message, time) => {
    return new Promise((resolve) => {
      setTimeout(() => {
        resolve(message);
      }, time);
    });
  }

  const removeMaxBidAmount = async () => {
    const currentTime = new Date().getTime()
    if (currentTime - lastUpdatedBidAmountTime < maxBidUpdateInterval) {
      setRemoveMaxBidProcessing("Processing… It can take up to 20 seconds to remove your bid.")
      const res = await resolveAfter("resolved", maxBidUpdateInterval - (currentTime - lastUpdatedBidAmountTime))
      if (res === "resolved") {
        setRemoveMaxBidProcessing("")
      } else {
        setRemoveMaxBidProcessing("Processing… It can take up to 20 seconds to remove your bid.")
      }
    }
    setLastUpdatedBidAmounTime(currentTime)
    setMaxBidLoading(true)
    try {
      const response = await axios.delete(`${lambdaUrl}/1_autobidding/maximum-bid-amount/${broker_id}/${card.application_id}`, {
        headers: {
          authorizationtoken: localStorage.getItem("token")
        }
      })
      refetchMaxBidAmount()
      setMaxBidLoading(false)
      setMaxBidAmountAdded(false)
      setMaxBidAmountSet(false)
      setAlertMessage("success", response.data.message)
    }
    catch (error) {
      setMaxBidLoading(false)
      error.response.status === 401 ? setAlertMessage("info", error.response?.data?.message) : setAlertMessage("error", error.response?.data?.message)
    }
  }

  const handleCloseMaxBidRemainderModal = () => {
    setMaxBidAmountRemainderModal(false)
    maxBidAmountSetValue("maxBidAmount", "")
    setMaxBidAmountAdded(false)
    setMaxBidAmountSet(false)
  }

  return (
    <UICardView>
      <Container fluid className="mb-4 ui-card-dashboard margin-auto">
        {
          alertmsg.length > 0 && (<Snackbar
            className='snackbar-alert'
            anchorOrigin={{ vertical: "top", horizontal: "center" }}
            open={open}
            autoHideDuration={5000}
            key={alertKey}
            onClose={handleClose} // ? Close when click anywhere
          >
            <Alert
              onClose={handleClose} // ? Close when click on close icon
              variant="filled" severity={severity}>
              {alertmsg}
            </Alert>
          </Snackbar>)
        }
        <Row>
          <Col xl={12}>
            <Card>
              <Card.Body className="p-0">
                <Row className="full-card-row">
                  <Row className="app-id-row">
                    <Col className="avatar mb-2">
                      <img
                        className="user-img text-center p-1" alt="User" src={User} /></Col>
                    <Col>
                      <div className="card-label">Application ID: {(card.application_id === "" || null || undefined) ? "No Data" : card.application_id}</div>
                    </Col>
                    <Col>
                      <div className="card-label loan-amount">Loan Amount: <span className='loan-amount-color'>{(card.loan_amount === "" || null || undefined) ? "No Data" : `$${parseInt(card.loan_amount).toLocaleString("en-US")}`}</span></div>
                    </Col>
                  </Row>

                  <Col className="application" xs={12} sm={12} md={6} lg={5} xl={4} xxl={5}>
                    {/* BODY */}
                    <Row className='app-head-row'>

                      <Col md={12}>
                        <Row className="app-details-row">
                          <Col>
                            <p className="details-p">Household income : {(card.household_income === "" || null || undefined) ? "No Data" : `${parseInt(card.household_income).toLocaleString("en-US") === 'NaN' ? card.household_income : `$${parseInt(card.household_income).toLocaleString("en-US")}`}`}</p>
                          </Col>
                        </Row>
                      </Col>

                      <Col md={12}>
                        <Row className="app-details-row">
                          <Col><p className="details-p">Property value : {(card.property_value === "" || card.property_value === null || card.property_value === undefined) ? "No Data" : `$${parseInt(card.property_value).toLocaleString("en-US")}`}</p>
                          </Col>
                        </Row>
                      </Col>

                      <Col md={12}>
                        <Row className="app-details-row">
                          <Col><p className="details-p">Deposit amount : {(card.deposit_amount === "" || card.deposit_amount === null || card.deposit_amount === undefined) ? "No Data" : `$${parseInt(card.deposit_amount).toLocaleString("en-US")}`}</p></Col>
                        </Row>
                      </Col>

                      <Col md={12}>
                        <Row className="app-details-row">
                          <Col><p className="details-p">Deposit type : {(card.deposit_type === "" || card.deposit_type === null || card.deposit_type === undefined) ? "No Data" : card.deposit_type}</p></Col>
                        </Row>
                      </Col>

                      <Col md={12}>
                        <Row className="app-details-row">
                          <Col><p className="details-p">Employment type : {(card.employment_type === "" || null || undefined) ? "No Data" : card.employment_type}</p></Col>
                        </Row>
                      </Col>

                      <Col md={12}>
                        <Row className="app-details-row">
                          <Col><p className="details-p">Enquiry type : {(card.enquiry_type === "" || card.enquiry_type === null || card.enquiry_type === undefined) ? "No Data" : card.enquiry_type} </p></Col>
                        </Row>
                      </Col>

                      {(card.enquiry_type?.toLowerCase() === "refinance" && card.current_lender.length !== 0) && <Col md={12}>
                        <Row className="app-details-row">
                          <Col><p className="details-p">Current Lender : {(card.current_lender === "" || null || undefined) ? "No Data" : card.current_lender}</p></Col>
                        </Row>
                      </Col>}

                      {(card.enquiry_type?.toLowerCase() === "refinance" && card.existing_loan_type.length !== 0) && <Col md={12}>
                        <Row className="app-details-row">
                          <Col><p className="details-p">Existing Loan Type : {(card.existing_loan_type === "" || null || undefined) ? "No Data" : card.existing_loan_type}</p></Col>
                        </Row>
                      </Col>}

                      <Col md={12}>
                        <Row className="app-details-row">
                          <Col><p className="details-p">Best time to contact :  {(card.best_time_to_contact === "" || null || undefined ? "No Data" : moment.utc(card.best_time_to_contact).tz(moment.tz.guess()).format('DD-MM-YYYY hh:mm a'))}</p></Col>
                        </Row>
                      </Col>

                      <Col md={12}>
                        <Row className="app-details-row">
                          <Col><p className="details-p">State : {(card.state === "" || null || undefined ? "No Data" : card.state)}</p></Col>
                        </Row>
                      </Col>

                      <Col md={12}>
                        <Row className="app-details-row">
                          <Col><p className="details-p">Post code : {(card.postcode === "" || null || undefined ? "No Data" : card.postcode)}</p></Col>
                        </Row>
                      </Col>

                    </Row>
                  </Col>
                  {/* Bidding Column */}
                  <Col className="bid-col">
                    <div className='bidding-area'>
                      <div className=''>
                        <div className='max-bid-row'>
                          <div className='max-bid-text'>
                            Add your maximum bid amount :<React.Fragment>
                              <IconButton
                                aria-label="help"
                                size='small'
                                aria-haspopup="true"
                                className='help-icon'
                                onClick={(e) => setAnchorEl(e.currentTarget)}
                              >
                                <HelpIcon fontSize='small' />
                              </IconButton>
                              <Popover
                                className='note-popover'
                                id="mouse-over-popover"
                                open={openPopOver}
                                anchorEl={anchorEl}
                                onClose={() => setAnchorEl(null)}
                                anchorOrigin={{
                                  vertical: 'bottom',
                                  horizontal: 'right',
                                }}
                                PaperProps={{
                                  className: 'note-popover-paper'
                                }}
                                disableRestoreFocus
                              >
                                <div className='max-bid-note'>
                                  {maxBidNote}
                                </div>
                              </Popover>
                            </React.Fragment>
                          </div>

                          {(maxBidAmountAdded) ?
                            <div className='max-bid-input'>
                              <Form noValidate className='d-flex gap-2 align-items-baseline' onSubmit={maxBidHandleSubmit(maxBidAmountSubmit)}>
                                <div>
                                  <Form.Control
                                    className="text-center max-bid-input-control fw-bold px-1"
                                    type="text"
                                    placeholder={"$0"}
                                    inputMode="numeric"
                                    name="maxBidAmount"
                                    id="maxBidAmount"
                                    maxlength={5}
                                    {...maxBidAmountReg("maxBidAmount", {
                                      onChange: (event) => {
                                        const { value } = event.target
                                        event.target.value = normalizeNumber(value);
                                        if (maxBidAmount?.data?.MaxBidAmount === parseInt(event.target.value)) {
                                          setMaxBidAmountSet(true)
                                        } else {
                                          setMaxBidAmountSet(false)
                                        }
                                      },
                                    })}
                                    isInvalid={maxBidAmountError.maxBidAmount}
                                    onFocus={(e) => {
                                      e.target.placeholder = ""
                                      setFocusedMaxBidAmount(true)
                                    }
                                    }
                                    onBlur={(e) => {
                                      setFocusedMaxBidAmount(false)
                                    }
                                    }
                                  />
                                </div>

                                {maxBidAmountSet && maxBidAmountAdded ? <Button
                                  className='max-bid-button'
                                  id='remove'
                                  type="button"
                                  disabled={maxBidLoading || maxBidFetchLoading}
                                  onClick={
                                    removeMaxBidAmount
                                  }
                                >
                                  {maxBidLoading ? <Spinner className='spinner-size' animation="border" variant="light" /> : "Remove"}
                                </Button>
                                  :
                                  <Button
                                    disabled={maxBidLoading || maxBidFetchLoading}  // ? Disabling the button when the maxBidAmount is loading
                                    className='max-bid-button'
                                    id='confirm'
                                    type="submit"
                                  >
                                    {maxBidLoading ? <Spinner className='spinner-size' animation="border" variant="light" /> : maxBidAmount?.data?.MaxBidAmount ? "Update" : "Set"}
                                  </Button>
                                }
                              </Form>
                            </div>
                            :
                            <Button className='max-bid-add-button' onClick={() => {
                              setMaxBidAmountAdded(true)
                            }}>
                              Add
                            </Button>}
                        </div>

                        {maxBidAmountError.maxBidAmount?.message && !removeMaxBidProcessing &&
                          <div type="invalid" className='max-bid-amount-error'>
                            {maxBidAmountError.maxBidAmount?.message}
                          </div>}

                        {removeMaxBidProcessing && <div className='max-bid-amount-error'>{removeMaxBidProcessing}</div>}

                        {maxBidAmount?.data?.AutoBidStatus === "Off" && card?.out_bid_bidder && !removeMaxBidProcessing
                          && maxBidAmount?.data?.MaxBidAmount && <div className='max-bid-stay-warning'>Edit your maximum bid to stay in the lead</div>}

                        <div className='d-flex flex-column flex-sm-row justify-content-between align-items-center mt-2'>
                          <div className='current-bid'>Current Bid:<span className='current-bid-amount'
                          >{card?.current_bid === "" ? "0" : ` $${card?.current_bid}`} </span>
                          </div>
                          <div className='bid-status'>{
                            (card?.highest_bidder && <div className='highest-bidder text-center'>Highest Bidder</div>) ||
                            (card?.out_bid_bidder && <div className='out-bid text-center'>Out Bid</div>)
                          }
                          </div>
                        </div>

                        <div className='total-bids'>
                          Total no of Bids:
                          <span className='total-no-bids'> {(card?.total_bids === "" || null || undefined) ? "No Data" : `${card?.total_bids}`}</span>
                        </div>

                        {card?.broker_bidding_data.length !== 0 && <div className='top-three-bids'>
                          <span>Top 3 Bids:</span>
                          <span className='view-all' onClick={handleViewAllButton} >View all</span>
                        </div>}

                        <TopThreeBids viewAllData={card?.broker_bidding_data} />

                        <Row>
                          <div className='bid-form-space'>
                            <div className='bid-recommendataion'>{`Bid $${parseInt(card?.current_bid) + parseInt(card?.min_increment)} or more:`}</div>
                            <Form noValidate className='form-alignment mx-3 p-1 flex-grow-1' onSubmit={handleSubmit(onSubmit)}>
                              <Form.Control
                                className="placeBid-form p-1  text-center fw-bold"
                                type="text"
                                placeholder={"$0"}
                                inputMode="numeric"
                                name="bidAmount"
                                id="bidAmount"
                                maxlength={card.current_bid.length + 1}
                                {...register("bidAmount", {
                                  onChange: (event) => {
                                    const { value } = event.target
                                    event.target.value = normalizeNumber(value);
                                  }
                                })}
                                isInvalid={errors.bidAmount}
                                onFocus={(e) => e.target.placeholder = ""}
                              />
                              {errors.bidAmount?.message &&
                                <Form.Control.Feedback type="invalid" className='error-form'>
                                  {errors.bidAmount?.message}
                                </Form.Control.Feedback>}
                              <Button
                                className='p-2 button-form'
                                id='confirm'
                                type="submit"
                              >
                                {placeBidLoader ? <Spinner animation="border" variant="light" className='spinner-size' /> : "Place Bid"}
                              </Button>
                            </Form>
                          </div>
                        </Row>
                      </div>
                    </div>
                  </Col>

                  <Row className="notes">
                    <Col className="notes-col">
                      <p className="notes-p fw-bold"> Notes: </p>
                      <p className="notes-p pl-2">
                        {card.notes === "" ? "No data" : ` ${card.notes}`}
                      </p>
                    </Col>
                  </Row>

                </Row>
              </Card.Body>
            </Card>
          </Col>
        </Row>

        <ConfirmModal
          show={openConfirmModal}
          modalData={modalData.current}
          setOpenConfirmModal={setOpenConfirmModal}
          postAmount={postAmount}
        />

        <ViewAll
          show={viewAllModal}
          onHide={() => setViewAllModal(false)}
          viewAllData={viewAllData}
          viewAllLoading={viewAllLoading}
        />

        <MaxBidRemainder
          show={maxBidAmountRemainderModal}
          onHide={handleCloseMaxBidRemainderModal}
          setBid={handleSetBidConfirm}
        />

      </Container>
    </UICardView>

  )
}
export default React.memo(UICard);