import axios from 'axios';

export const api = axios.create(
    {
        baseURL: 'https://testapi.bidlead.com.au',
        headers: {
            "x-api-key": "fnn3TYLJlU1N1VpQv064FfJK5KWhe455tBNXyLyh",
        }
    }
);

export const lambdaUrl = "https://z10c24f9wk.execute-api.ap-south-1.amazonaws.com/test";
