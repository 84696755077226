import styled from "styled-components";

export const SessionTimeoutWrap = styled.div`
.login_button {
    background-color: var(--orange-color) !important;
    border: none;
}
.sessionTimeout_msg {
    font: normal normal 500 18px/27px Montserrat;
    letter-spacing: -0.36px;
    margin-bottom: 0px;
}

`