import Styled from "styled-components";

const HowItWorksWrap = Styled.div`

    @import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;700&display=swap');

    h5{
        font-weight: bold;
        font-size: 18px;
        margin-top: 10px;
    }
    P{
        font-size: 14px;
    }
    .main-content{
        line-height: 40px;
        margin-top: 10px;

    }
    .contents{
        margin: 0px 120px 0px 120px;
        // margin-right:120px;
    }
    .iframe{
        margin-right: 0px;
    }
    .card1 .iframe{
        float: right;
        margin-top: 30px;
        width: 80%;
        height: 170px;
    }
    .contents1{
        display: flex;

    }
    .numbers1 .num{
        font-weight: bold;
        font-size: 32px;
        margin-top: 8px;
        color: #FF9B15;
        opacity: 50%;
        position: relative;
    }
    .numbers1{
        display: flex;
    }
    .numbers1 .num1{
        font-weight: bold;
        font-size: 18px;
        margin-top: 16px; 
        right: 12px;
        color: #FF9B15;
        position: relative;
        z-index: 2;
    }
    
    
    .contents1 h6{
        font-weight: bold;
        margin-top: 25px;
        margin-left: 10px;
        font-size: 18px;
    }
    .list1 li{
        font-size: 14px;
        font-weight: 500;
        margin-left: 60px;
        line-height: 24px;
        list-style: inherit;
    }


    @media (max-width: 800px){
        .card1 .iframe{
            margin-top: 40px;
            width: 340px;
            height: 190px;
        }
    }
    @media (max-width: 768px){
        .card1 .iframe{
            width: 100%;
            height: 170px;
        }
    }
    @media (max-width: 500px){
        .card1 .iframe{
            width: 100%;
            height: 170px;
        }
        .padder {
            padding: 0;
        }
    }
    @media(max-width: 1024px){
        .video{
            margin-right: 30px;
        }
        .card1 .iframe{
            max-width: 100%;
            height: 160px;
        }
    }
    @media (max-width: 768px){
        .contents{
            margin-left:30px;
            margin-right:30px;
        }
    }

        
`;

export default HowItWorksWrap;
