import React from 'react';
import NotFoundWrap from '../PageNotFound/style.js';

const PageNotFound = () => {
	return (
		<>
			<h1 className='d-flex justify-content-center'>404</h1>
			<h2 className='d-flex justify-content-center'>Page Not Found</h2>
			<NotFoundWrap>
				<figure>

					<div className="sad-mac"></div>

					<figcaption>
						<span className="sr-text ">Error 404: Not Found</span>
						<span className="e"></span>
						<span className="r"></span>
						<span className="r"></span>
						<span className="o"></span>
						<span className="r"></span>
						<span className="_4"></span>
						<span className="_0"></span>
						<span className="_4"></span>
						<span className="n"></span>
						<span className="o"></span>
						<span className="t"></span>
						<span className="f"></span>
						<span className="o"></span>
						<span className="u"></span>
						<span className="n"></span>
						<span className="d"></span>
					</figcaption>
				</figure>
			</NotFoundWrap>
		</>
	)
}

export default PageNotFound;