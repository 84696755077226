import { combineReducers } from "redux";
import { LayoutReducer } from "./Layout/reducers";
import { AuthReducer } from "./Auth/reducers";
import { DataReducer } from "./Data/reducers";

const rootReducers = combineReducers({
  Layout: LayoutReducer,
  Auth: AuthReducer,
  Data: DataReducer
});

export default rootReducers;
