import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Link, NavLink } from "react-router-dom";
import PersonIcon from '@mui/icons-material/Person';
import HeaderWrap from "./style";
import logoutB from "./logoutB.svg";
import './menu.css'
import { userLogout } from "../../../redux/Auth/actionCreator";
import BurgerMenuButton from "../UIButton/AnimationButton";
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import InfoIcon from '@mui/icons-material/Info';
import { Button, Modal } from "react-bootstrap";
import Dropdown from 'react-bootstrap/Dropdown';
import BidLead from "../../../assets/images/icons/BidLead.svg"
import { api } from "../../../Services/api"
import "bootstrap/dist/css/bootstrap.min.css"
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { postLogoutUser } from "../../DefinedApi/PostLogoutUser";
import NotificationsRoundedIcon from '@mui/icons-material/NotificationsRounded';
import Loading from "../Loading/Loading";
import { useFetch } from "../../../Hooks/useFetch";
import { fiveMinTimerNote } from "../../../config/constant";

const UIHeader = ({ setSessionTimeOut, ProfileInfo, dashboardDataLeads }) => {

  const navlink = [
    { item: dashboardDataLeads.length > 0 ? "Live Auction" : "Dashboard", navLink: "dashBoard" },
    { item: "Bid History", navLink: "BidHistory" },
    { item: "How it Works", navLink: "howitworks" },
    { item: "My Profile", navLink: "profile" },
  ]

  const dispatch = useDispatch();
  const [anchorEl, setAnchorEl] = React.useState(null)
  const [logoutModal, setLogoutModal] = useState(false)
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const onSuccess = (data) => {
    console.log("[onSuccess] GetProfileData--> ", data)
  }
  const onError = (error) => {
    if (error.response.status === 403 || error.response.status === 404) {
      setSessionTimeOut(true)
      postLogoutUser()
    }
  }

  const { data: profile } = useFetch("getHeaderProfileData", "/bid-leads/profile", onSuccess, onError, false, true)

  useEffect(() => {
    ProfileInfo(profile)
  }, [profile, ProfileInfo])

  const showNote = useSelector((state) => state.Auth.showNote)

  const postLogout = async () => {
    try {
      const response = await api.post("/broker-auth/logout", {}, {
        headers: {
          authorizationToken: localStorage.getItem("token"),
        }
      })
      console.log("[postLogout] response-->", response)
      response.data.logout && navigate("/login");
    }
    catch (error) {
      console.log("[postLogout] error-->", error.response)
    }
  }

  const { data: notifications, isLoading: notificationLoading, refetch: getNotification } = useFetch("userNotification", "/bid-leads/broker-notifications", () => { }, () => { }, false, false)

  let navigate = useNavigate();
  const logoutUser = () => {
    let result = {
      isLoggedIn: false,
      authToken: "",
      user: ""
    }
    postLogout()
    dispatch(userLogout(result));
  };
  const handleLogoClick = () => {
    return navigate(navlink[0].navLink)
  }
  const handleonclickworks = () => {
    return navigate('howitworks')
  }
  const handleonclickprofile = () => {
    return navigate('profile')
  }
  return (
    <HeaderWrap>
      <nav className="navbar navbar-expand-md navbar-light bg-white shadow position-fixed w-100 z-index-navbar">
        <div className="container-fluid pe-0">
          <div className="menu-display">
            <div className="hide-menu">
              <div>
                <div className="border-0 p-0 btn"
                  id="basic-button"
                  aria-controls={open ? 'basic-menu' : undefined}
                  aria-haspopup="true"
                  aria-expanded={open ? 'true' : undefined}
                  onClick={handleClick}>
                  <BurgerMenuButton open={open} />
                </div>
                <Menu
                  id="basic-menu"
                  className="basic-menu"
                  anchorEl={anchorEl}
                  open={open}
                  onClose={handleClose}
                  MenuListProps={{
                    'aria-labelledby': 'basic-button',
                  }}
                >
                  <div className="position-relative">
                    {navlink.map((nav) => {
                      return (
                        <MenuItem key={nav.navLink} className="fw-bold blue-theme" onClick={handleClose} >
                          <NavLink className="fw-bold blue-theme" to={nav.navLink}>
                            <div className="menu-item">
                              {nav.item}
                            </div>
                          </NavLink>
                        </MenuItem>
                      )
                    })}
                    <MenuItem
                      className="fw-bold blue-theme "
                      to="#"
                      type="button"
                      onClick={() => {
                        handleClose()
                        setLogoutModal(true)
                      }}
                      data-bs-toggle="modal" title="Logout"
                    >
                      <div className="menu-item">
                        Logout
                      </div>
                    </MenuItem>
                  </div>
                </Menu>
              </div>

            </div>
          </div>
          <div className="navbar-item">
            <div className="d-inline-block text-truncate">
              <button className="btn" onClick={handleLogoClick} >
                <img className="bid-logo" src={BidLead} alt="logo" />
              </button>
            </div>
          </div>
          <div className="collapse navbar-collapse justify-content-start" id="navbarNavAltMarkup">
            <ul className="navbar-nav">
              <li className="nav-item">
                <Link
                  className="log pt-0 nav-link"
                  to="#"
                  type="button"
                  onClick={() => setLogoutModal(true)}
                  data-bs-toggle="modal" title="Logout">
                  <div className="profileLetter d-flex justify-content-center align-items-center">
                    <img src={logoutB} height={29} width={29} alt="Logout"></img>
                  </div>
                </Link>
              </li>
              {navlink.map((nav, index) => {
                return index <= 1 && (
                  <li key={nav.item + index} className="nav-item mx-2">
                    <NavLink className=" navbar-brand nav-link" to={nav.navLink} title={nav.item}>
                      {dashboardDataLeads.length > 0 && nav.navLink === "dashBoard" ? <span className="blinking-dot"></span> : null} {nav.item}
                    </NavLink>
                  </li>
                )
              })}
            </ul>
          </div>
          <li className="nav-item">
            <Dropdown
              className="profile nav-link p-0"
              to="#"
              title="profile">
              <Dropdown.Toggle className="px-3" id="profile-dropdown">
                <div className="position-relative">
                  <div className="profileLetter text-capitalize d-flex justify-content-center align-items-center">{profile ? profile?.first_name[0] :
                    <PersonIcon className="mr-0" />}</div>
                </div>
              </Dropdown.Toggle>
              <Dropdown.Menu>
                <Dropdown.Header className="dropheader">
                  {profile?.first_name} {profile?.last_name}
                </Dropdown.Header>
                <Dropdown.Item className="infoworks" onClick={handleonclickworks}>
                  <Link to="howitworks">
                    <InfoIcon className="p-1 fs-2" />
                    How it works
                  </Link>
                </Dropdown.Item>
                <Dropdown.Item className="accountblack" onClick={handleonclickprofile}>
                  <Link to="profile">
                    <AccountCircleIcon className="p-1 fs-2" />
                    My Account
                  </Link>
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </li>
          <li className="nav-item">
            <Dropdown
              className="profile nav-link p-0"
              to="#"
              type="button"
              onClick={getNotification}
              title="Notification"
            >
              <Dropdown.Toggle className="p-0" id="profile-dropdown">
                <div className="position-relative">
                  <div className="profileLetter text-capitalize d-flex justify-content-center align-items-center">
                    <NotificationsRoundedIcon className="mr-0" /></div>
                </div>
              </Dropdown.Toggle>
              <Dropdown.Menu>
                <Dropdown.Header className="dropheader">
                  Notifications
                </Dropdown.Header>
                {notificationLoading ? <Loading /> : notifications?.map((notification) => {
                  return (
                    <Dropdown.Item className="notification" key={notification.application_id}>
                      <span className="notification-message">{notification.message}</span>
                    </Dropdown.Item>
                  )
                })}
              </Dropdown.Menu>
            </Dropdown>
          </li>
        </div>
        <Modal show={logoutModal}
          onHide={() => { setLogoutModal(false) }}
          id="exampleModal"
        >
          <Modal.Header
            className="border-0 d-flex justify-content-center pb-0"
          >
            <Modal.Title> Logout User </Modal.Title>
          </Modal.Header>
          <Modal.Body className="text-center">Are you sure want to Logout?</Modal.Body>
          <Modal.Footer className="border-0 d-flex justify-content-center ">
            <HeaderWrap>
              <Button
                className="mx-2 close_button"
                variant="secondary"
                onClick={() => setLogoutModal(false)}>
                Close
              </Button>
            </HeaderWrap>

            <HeaderWrap>
              <Button
                className="mx-2 log_out_button"
                onClick={() => {
                  setLogoutModal(false)
                  logoutUser()
                }}
                id="yes-button"
              >
                Logout
              </Button>
            </HeaderWrap>
          </Modal.Footer>
        </Modal>
      </nav>

      {showNote && dashboardDataLeads.length > 0 && <div className="five-min-timer-note">{fiveMinTimerNote}</div>}
    </HeaderWrap>
  );
};
export default UIHeader;