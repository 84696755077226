import { useQuery } from "@tanstack/react-query"
import { api } from "../Services/api"



export const useFetch = (queryKey, url, onSuccess, onError, interval = false, enabled = false) => {
    return useQuery(
        [queryKey],
        async () => {
            return await api.get(url, {
                headers: {
                    "authorizationToken": localStorage.getItem("token"),
                },
            })
                .then((res) => {
                    return res.data
                })
        },
        {
            refetchInterval: interval,
            onSuccess,
            onError,
            // cacheTime: 30000,
            enabled,
            refetchOnWindowFocus: false,
            refetchOnMount: true,
        })
}