import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import Container from "react-bootstrap/Container";
import { Col, Row, Alert, Button } from "react-bootstrap";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Forms } from '../../../components/Reuseable/Forms';
import ForgotPasswordWrap from "./style";
import { userLogin } from "../../../redux/Auth/actionCreator";
import hashicon from "../../../assets/images/login/hashicon.svg";
import hashimg from "../../../assets/images/login/hashimg.svg";
import { api } from '../../../Services/api';
import { Validators } from "../../../Utilities/validator";
import { Link } from "react-router-dom";

const ForgotPassword = () => {
  const [invalidUser, setInvalidUser] = useState(false);
  const dispatch = useDispatch();
  const history = useNavigate();
  const [loading, setLoading] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const [changeInForm, setChangeInForm] = useState(false);

  const resetPassword = (form, valid) => {
    if (valid) {
      setLoading(true);
      const obj = {
        userid: form.email,
        updatedpassword: form.newpassword
      }
      api.patch('/trackinguserauth', obj).then(function (response) {
        if (response.status == 200) {
          history.push('/login');
          setSubmitted(true);
        }
      }).catch(function (error) {
        if (error.response) {
          if (error.response.status == 401)
            setInvalidUser(true);
          console.log(error.response.data);
        } else if (error.request) {
          console.log(error.request);
        } else {
          console.log('Error', error.message);
        }
        setLoading(false);
      });
    }
  };

  return (
    <>
      <ForgotPasswordWrap>
        <section className="loginPageContent">
          <Container fluid>
            <Row className="loginPageRow">
              <Col
                className="loginPageLeftside p-0"
                lg={6}
                md={12}
                sm={12}
              >
              </Col>
              <Col
                className="loginPageRightside  ps-0 pe-0"
                lg={6}
                md={12}
                sm={12}
              >
                <div className="loginFormScreens flex-fill bg-white">
                  <div className="loginScreenLogoMain text-center pt-5 pb-2">
                    <img
                      className="img-fluid mx-auto loginScreenLogoimg"
                      src="https://customlogo.s3.ap-south-1.amazonaws.com/admin-light-mode-logo.svg1"
                      alt="trekr icon"
                    />
                  </div>
                  <h4 className="text-center login-heading">Trekr</h4>
                  {invalidUser ? <Alert className="m-3 p-1 text-center" variant="danger"> Invalid User</Alert> : <></>}
                  <Forms title={""} formArr={[
                    {
                      label: "Email",
                      name: "email",
                      type: "email",
                      placeholder: "Email Address",
                      validators: [
                        { check: Validators.required, message: "This Field is required" },
                        { check: Validators.email, message: "Email is not Valid" }
                      ],
                      error: false,
                      errormsg: "",
                      value: "",
                      show: true
                    },
                    {
                      label: "New Password",
                      name: "newpassword",
                      type: "password",
                      placeholder: "New Password",
                      validators: [
                        { check: Validators.required, message: "This Field is required" },
                        { check: Validators.password, message: "Enter atleast 6 characters" }
                      ],
                      error: false,
                      errormsg: "",
                      value: "",
                      show: true
                    },
                    {
                      label: "Confirm Password",
                      name: "confirmpassword",
                      type: "password",
                      placeholder: "Confirm Password",
                      validators: [
                        { check: Validators.required, message: "This Field is required" }
                      ],
                      error: false,
                      errormsg: "",
                      value: "",
                      match: "newpassword",
                      show: true
                    }
                  ]} submitBtn={"Reset"} formlinks={<Link to='/login' className="forgotLink">Back to login</Link>} onSubmit={resetPassword} loading={loading} submitted={submitted} changeInForm={changeInForm} setChangeInForm={setChangeInForm} />

                </div>
              </Col>
            </Row>
          </Container>
        </section>
      </ForgotPasswordWrap>
    </>
  );
};

export default ForgotPassword;
