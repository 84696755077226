import Styled from "styled-components";
import slide1 from "../../../assets/images/slide1.svg";
import slide2 from "../../../assets/images/slide2.svg";

const ForgotPasswordWrap = Styled.div`

.loginScreenLogoimg {
    max-height: 56px;
}
.loginPageContent {
    overflow: hidden;
    height: 100vh;
}
.loginFormScreens {
    overflow: hidden;
    overflow-y: hidden;
    height: 100vh;
}
.logoimg {
    max-height: 50px;
}
.formScreenUser {
    color: #111247;
    font-weight: 600;
}
.formScreenTitle {
    color: #555;
    font-weight: 600;
}
.forgotLink {
    color:  ${({ theme }) => theme["orange-color"]};
    font-size: 14px;
    font-weight: 500;
    &:hover {
        color: ${({ theme }) => theme["orange-hover"]};
    }
    &:active {
        color: ${({ theme }) => theme["orange-hover"]};
    }
    &:focus {
        color: ${({ theme }) => theme["orange-hover"]};
    }
}
.loginFormScreens {
    .form-control {
        padding: 10px 20px;
        font-size: 15px;
    }
}
form {
    label {
        color: ${({ theme }) => theme["gray-color"]};
    }
}
.singleSlide {
    margin: 0;
    background-size: cover;
    background-position: top center;
    background-repeat: no-repeat;
    position: relative;
}
.slide1 {
    background-image: url(${slide1});
    height: 100vh;
}
.slide2 {
    background-image: url(${slide2});
    height: 100vh;
}
.slideOverlay {
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    background-color: #4b58614d;
}
.loginScreenSlides {
    .slick-dots {
        position: absolute;
        bottom: 56px;
        display: block;
        padding: 0;
        margin: 0 auto;
        list-style: none;
        text-align: center;
        // right: -40%;
        bottom: 16px;
        li {
            button {
                font-size: 0;
                line-height: 0;
                display: block;
                width: 11px;
                height: 11px;
                padding: 5px;
                cursor: pointer;
                color: transparent;
                border: 0;
                outline: none;
                // background: #a5abb1;
                background: #fff;
                border-radius: 50%;
                &:before {
                    color: transparent !important;
                }
            }
        }
        li.slick-active {
            button {
                background: ${({ theme }) => theme["orange-color"]};
            }
        }
    }
}
.loginScreenSlides.slick-dots {
    li.slick-active {
        button {
            &:before {
                color: transparent !important;
            }
        }
    }
}   
.loginPageLeftside{
    top: 0px;
    left: 0px;
    background-position : center center;
}
.login-heading{
    font-weight : 600;
    color: grey
}
@media (max-width:1199px)
{
.loginPageLeftside {
background-size: 90%;
}
}
@media (max-width:991px)
{

.loginPageRow {
-ms-flex-direction: column-reverse!important;
flex-direction: column-reverse!important;
}
.loginFormScreens {
overflow: inherit;
height: inherit;
}
.loginPageLeftside {
background-size: contain;
background-position: center top;
min-height: 465px;
}
.loginPageContent {
overflow: hidden;
overflow-y: auto;
height: auto;
}
}
@media (max-width:575px)
{
.formBody.loginformbody {
padding: 15px !important;
}
}
`;

export default ForgotPasswordWrap;
