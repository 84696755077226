import React from "react";
import HowItWorksWrap from "./style";
import 'bootstrap/dist/css/bootstrap.min.css';
import Iframe from 'react-iframe';
import {Row, Col} from "react-bootstrap";
import Data from "./data.json";
const HowItWorks = () => {

    return(
        <HowItWorksWrap>
          <div className='contents'>
            <h5>How it works</h5>
            <p>This is how you can easily connect with leads all over Australia</p>
          </div>
          <div className="card1 contents" >
            {
              Data.map(record => {
                return(
                  <div className="" key={record.id}>
                    <Row>
                      <Col md={8} className="padder">
                        <div className="main-content">
                          <div className="contents1">
                            <div className="numbers1">
                              <p className="num">{record.num1}</p>
                              <p className="num1">{record.num2}</p>
                            </div>
                            <h6>{record.title}</h6>
                          </div>
                          <div className="list1">
                              <ul>
                                <li>{record.list1}</li>
                                <li>{record.list2}</li>
                              </ul>
                          </div>
                        </div>
                      </Col>
                      {/* <Col md={6}>
                        <Iframe url={record.video} frameborder="0"  className="iframe" display="initial"/>
                      </Col> */}
                    </Row>
                  </div>
                )
              })
            }
        </div> 
        </HowItWorksWrap>   
    );

}
export default HowItWorks;
