import { api } from "../../Services/api"


export const postLogoutUser = async () => {
    try {
        const response = await api.post('/broker-auth/logout', {}, {
            headers: {
                authorizationtoken: localStorage.getItem("token"),
            }
        })
        console.log("logoutResponse: ", response)

    } catch (error) {
        console.log(error)
    }
}