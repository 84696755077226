import React from "react";
import { Modal } from "react-bootstrap";
import { ReleaseNotesWrapper } from "./style";
import UIModal from "../UIModal";

const ReleaseNotes = ({ showModal, setShowModal, size = "lg", content }) => {

    return (
        <ReleaseNotesWrapper>
            <UIModal
                show={showModal}
                onHide={() => { setShowModal(false) }}
                size={size}
                header={<Modal.Title
                    className='text-center'
                    id="example-custom-modal-styling-title">
                    <ReleaseNotesWrapper>
                        <div className='termsNconditions-label'>Release Notes</div>
                    </ReleaseNotesWrapper>
                </Modal.Title>}
            >
                <ReleaseNotesWrapper>
                    <div className='contents'>
                        <ReleaseNotesWrapper>
                            <div className='preface-content'> {content?.preface} </div>
                        </ReleaseNotesWrapper>
                        {content?.notes?.map((releaseNote, index) => {
                            return (
                                <div className='mt-2' key={index}>
                                    <ul>
                                        <li>
                                            {releaseNote.title}
                                        </li>
                                    </ul>
                                    {releaseNote.note}
                                </div>
                            )
                        })}
                    </div>
                    <div className="contents">If you have any questions about these changes, contact our customer care team by email at <a href="customercare@hashching.com.au">customercare@hashching.com.au</a> or on <a href="tel:1800444744">1800 444 744.</a></div>
                </ReleaseNotesWrapper>
            </UIModal>
        </ReleaseNotesWrapper>
    );
};

export default ReleaseNotes;