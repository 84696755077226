import React, { useState, useEffect, memo, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import SideMenuBar from "../sidebar";
import FilterAltRoundedIcon from '@mui/icons-material/FilterAltRounded';
import LayoutWrap from "./style";
import $ from "jquery";
import { Outlet } from "react-router-dom";
import CloseIcon from '@mui/icons-material/Close';
import { useMedia } from 'react-use';
import { useLocation } from 'react-router-dom'
import UIHeader from "../../../Reuseable/UIHeader";
import { IconButton } from "@mui/material";
import SessionTimeout from "../../../Reuseable/UIModal/SessionTimeout";
import { api } from "../../../../Services/api";
import { client } from "../../../../App";
import ReleaseNotes from "../../../Reuseable/ReleaseNotes"
import { postLogoutUser } from "../../../DefinedApi/PostLogoutUser";
import { useFetch } from "../../../../Hooks/useFetch";
import { setDashboardData } from "../../../../redux/Data/actionCreator";

const Main = () => {

const dispatch = useDispatch()
const [SessionTimeOut, setSessionTimeOut] = useState(false)
const [dataFound, setDataFound] = useState(false)
const [dashBoardFilters, setDashBoardFilters] = useState([])
const [bidHistoryFilters, setBidHistoryFilters] = useState([])
const [dataInBidHistory, setDataInBidHistory] = useState(false)
const [showReleaseNotes, setShowReleaseNotes] = useState(false)
const [statesToFilter, setStatesToFilter] = useState([])
const [loanTypesToFilter, setLoanTypesToFilter] = useState([])
const [profile, setProfile] = useState('')

const InitialFilters = [
  { filter: "on-going", isChecked: false },
  { filter: "won", isChecked: false },
  { filter: "lost", isChecked: false },
]

const [bidFilters, setBidFilters] = useState(InitialFilters)

const showSessionTimeout = () => {
  setSessionTimeOut(true)
  postLogoutUser()
}

const firstCheck = useRef(true)
const isLiveEnded = useRef(true)

const onSuccess = (data) => {
  dispatch(setDashboardData(data.lead))
  isLiveEnded.current && getDashBoardData(data)
  isLiveEnded.current = false
  firstCheck.current = false
  setDataFound(true)
}

const onError = (err) => {
  isLiveEnded.current = true
  firstCheck.current = false
  setDataFound(false)
  if (err.response.status === 403 || err.response.status === 404) {
    showSessionTimeout()
  }
}

const { data: liveAuctionDetails, refetch, isLoading } = useFetch("dashBoardData", "/bid-leads/broker-live-auction", onSuccess, onError, 5000, true)

const dashBoardData = async (responsedata) => {
  
  let loanTypes = await responsedata.lead.map((card) => {
    return card.enquiry_type.length === 0 ? "No Data" : card.enquiry_type
  })

  let states = await responsedata.lead.map((card) => {
    return card.state.length === 0 ? "No Data" : card.state
  })

  const noDuplicateLoanTypes = [...new Set(loanTypes)]

  setLoanTypesToFilter(noDuplicateLoanTypes.map((loanTypes) => {
    return { loanTypes }
  }))

  const noDuplicateStateTypes = [...new Set(states)]

  setStatesToFilter(noDuplicateStateTypes.map((states) => {
    return { states }
  }))
}

const getDashBoardData = (responsedata, reset = false) => {
  if (reset) {
    setLoanTypesToFilter([])
    setStatesToFilter([])
  }
  dashBoardData(responsedata)
}

const panelview = useSelector((state) => state.Layout.panelview);
const showResposiveSideBar = useMedia('(max-width: 962px)');

const [active, setActive] = useState(false);
function togleMenu1() {
  $(".page-body-wrapper").removeClass("mobSideBar");
}

let path = useLocation()
const location = useLocation();
const hideSideMenuBarprofile = location.pathname.includes('/home/profile');
const hideSideMenuBarworks = location.pathname.includes('/home/howitworks');
const bidHistoryPage = location.pathname.includes('/home/BidHistory');

const dashboardDataLeads = client.getQueryData(["dashBoardData"])?.lead || []
const showNote = useSelector((state) => state.Auth.showNote)

const isDataInBidHistory = (response) => {
  setDataInBidHistory(response)
}

const handleToggle = () => {
  setActive(prev => !prev)
}
const handleCloseMenu = () => {
  setActive(false);
}

const closeSideBarWhenMenuisActive = (e) => {
  setActive(e);
}

const ProfileInfo = (profile) => {
  setProfile(profile)
}

const [releaseNotes, setReleaseNotes] = useState({})

const fetchReleaseNotes = async (id) => {
  try {
    const res = await api.get(`/broker-auth/release-note-read-status/${id}`, {
      headers: {
        authorizationToken: localStorage.getItem("token"),
      }
    })

    if (res.data.status) {
      if (res.data.message === "Not Viewed") {
        setShowReleaseNotes(true)
        setReleaseNotes(res.data.data.notes)
      } else {
        setShowReleaseNotes(false)
        console.log("[fetchReleaseNotes] Error response", res)
      }
    }
  } catch (error) {
    console.log('[fetchReleaseNotes] error-->', error)
  }
}

useEffect(() => {
  if (profile?.broker_id) {
    fetchReleaseNotes(profile?.broker_id)
  }
}, [profile])

return (
  <LayoutWrap showNote={(showNote && dashboardDataLeads.length > 0) ? true : false} >
    <div className="maincontainer-scroller">
      <UIHeader dashboardDataLeads={dashboardDataLeads} ProfileInfo={ProfileInfo} setSessionTimeOut={setSessionTimeOut} showResposiveSideBar={showResposiveSideBar} closeSideBarWhenMenuisActive={closeSideBarWhenMenuisActive} active={active} />
      <div className="container-fluid page-body-wrapper" onClick={togleMenu1}>

        {(!hideSideMenuBarprofile && !hideSideMenuBarworks && (loanTypesToFilter.length !== 0 || statesToFilter.length !== 0)) || (bidHistoryPage && dataInBidHistory) ?

          (<div className='position-relative'>
            <div onClick={() => setActive(!active)} className='toggle'>
              {!active ?
                <IconButton className="filter_menu_icon">
                  <FilterAltRoundedIcon className="menu" />
                </IconButton>
                : <CloseIcon className="closemenu" />}
            </div>

            <SideMenuBar active={active}
              dashBoardFilters={dashBoardFilters}
              setDashBoardFilters={setDashBoardFilters}
              handleToggle={handleToggle}
              path={path.pathname}
              handleCloseMenu={handleCloseMenu}
              showResposiveSideBar={showResposiveSideBar}
              loanTypesToFilter={loanTypesToFilter}
              statesToFilter={statesToFilter}
              setLoanTypesToFilter={setLoanTypesToFilter}
              setStatesToFilter={setStatesToFilter}
              setBidFilters={setBidFilters}
              bidFilters={bidFilters}
              setBidHistoryFilters={setBidHistoryFilters}
              bidHistoryFilters={bidHistoryFilters}
            />
          </div>) : <></>}

        <div
          className={
            "main-panel " + (panelview === "topmenu" ? "topmenu" : "")
          }
        ><div className="content-wrapper">

            <SessionTimeout
              show={SessionTimeOut}
              setSessionTimeOut={setSessionTimeOut}
              onHide={() => { }}
            />

            <ReleaseNotes showModal={showReleaseNotes} setShowModal={setShowReleaseNotes} size="lg" content={releaseNotes} />

            <Outlet context={{
              dataFound,
              setDataFound,
              dashBoardFilters,
              setDashBoardFilters,
              bidHistoryFilters,
              setBidHistoryFilters,
              getDashBoardData,
              SessionTimeOut,
              setSessionTimeOut,
              loanTypesToFilter,
              profile,
              isDataInBidHistory,
              liveAuctionDetails,
              refetch,
              isLoading,
              isLiveEnded,
              firstCheck,
            }} />
          </div>
        </div>
      </div>
    </div>
  </LayoutWrap >
);
};

export default memo(Main);
