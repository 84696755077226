import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { Alert, Col } from "react-bootstrap";
import { Forms } from '.';
import { userLogin } from "../../../redux/Auth/actionCreator";
import { api } from '../../../Services/api';
import { Validators } from "../../../Utilities/validator";
import { Link, useNavigate } from "react-router-dom";
import BidLead from "../../../assets/images/icons/BidLead.svg";

const LoginForm = ({ props }) => {
  const [invalidUser, setInvalidUser] = useState(false);
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const [changeInForm, setChangeInForm] = useState(false);
  let navigate = useNavigate()

  const SubmitLogin = async (form, valid) => {
    setInvalidUser(null);
    if (valid) {
      setLoading(true);
      form.password = form.password.trim();
      const obj = {
        email: form.email,
        password: form.password,
        remember_me: true
      }
      let endUser = 'User'
      props.user ? endUser = 'Broker' : endUser = 'Admin'

      try {
        const response = await api.post('/broker-auth/login', obj, {
        })
        console.log("[SubmitLogin] response-->", response);
        if (response.status === 200) {
          let result = {
            isLoggedIn: true,
            authToken: response.data.accessToken,
            user: endUser,
          }
          setSubmitted(true);
          dispatch(userLogin(result));
          setLoading(false);
          localStorage.setItem('isFirstTimeLoggin', response.data.first_time_login_status);
          response.data.first_time_login_status === true ? navigate('/termsOfUse') : navigate('/home/dashBoard')
        }
      } catch (error) {
        if (error.response) {
          setInvalidUser(error.response.data.message);
        } else if (error.request) {
          console.log("[SubmitLogin] error request-->", error.request);
        } else {
          console.log("[SubmitLogin] error message-->", error.message);
        }
        setLoading(false);
      }
    }

  };

  return (<>
    <div className="loginFormScreens my-3">
      <img className="logoimg" src={BidLead} alt="logo" />
      <h5 className="head fw-bold fs-5 mb-0 py-3 text-center">Hashching broker <br /> {props.tag}</h5>
      {invalidUser ? <Alert className="m-2 p-1 text-center" variant="danger">{invalidUser}</Alert> : <></>}

      <Forms title={""} formArr={[
        {
          label: "Email",
          name: "email",
          type: "email",
          placeholder: "Enter your email ID or phone number",
          validators: [
            { check: Validators.required, message: "This Field is required" },
            { check: Validators.email, message: "Email is not Valid" }
          ],
          error: false,
          errormsg: "",
          value: "",
          show: true
        },
        {
          label: "Password",
          name: "password",
          type: "password",
          placeholder: "Enter your Password",
          validators: [
            { check: Validators.required, message: "This Field is required" },
            { check: Validators.password, message: "Password is not Valid" }
          ],
          error: false,
          errormsg: "",
          value: "",
          show: true
        }
      ]}
        submitBtn={"Login"}
        formlinks={
          props.user && <Link to='//www.hashching.com.au/forgot-password' className="forgotLink">Forgot password?</Link>
        }
        onSubmit={SubmitLogin} loading={loading} submitted={submitted} changeInForm={changeInForm} setChangeInForm={setChangeInForm}
      />

      {props.user ? (<p className="account text-center">
        <Col>
          <div className="signup" ><p> Don't have an account? <Link to="//www.hashching.com.au/broker/broker-registration">Signup</Link></p></div>
        </Col>
      </p>) : <></>}
    </div>
  </>
  )
}

export default LoginForm;